import './FeatureShowcase.css';

function FeatureShowcase(props) {
    return (
        <div className='FeatureShowcaseContainer'>
            {props.icon}
            <p className='FeatureShowcaseTitle'>{props.title}</p>
            <p className='FeatureShowcaseText'>{props.text}</p>
        </div>
    )
}

export default FeatureShowcase;