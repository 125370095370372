import './ShortcutButton.css';
import React from 'react';
import { IconCommand, IconLetterKSmall, IconLetterK } from '@tabler/icons-react';

function ShortcutButton(props) {
    var textColor = "white";

    if (props.color === 'white') {
        textColor = 'black';
    }

    return (
        <button className="StandardButton" onClick={props.onClick} style={{backgroundColor:props.color}}>
            <span style={{color:textColor}}>{props.text}</span>

                {/* <IconCommand className='StandardButtonIcon' size={18} stroke={1.25} color='#AEAEAE'/>
                <IconLetterK className='StandardButtonIcon' size={18} stroke={1.5} color='#AEAEAE'/>
 */}
        </button>

    );
}

export default ShortcutButton;