import './Features.css';
import GPTAnswer from '../../components/GPTAnswer/GPTAnswer';
function Features() {


    return (
        <div className='FeatureContainer' id="features">
            <div className="FeatureTextContainer">
                <p className='FeatureTextTitle'>Anbud <span style={{ color: '#0075FF' }}>utan</span> huvudvärk</p>
                <p className='FeatureTextSubtitle'>Skrivhjälp som gör att ni kan lägga er tid på det som verkligen spelar roll</p>
            </div>
            <div className="FeatureBoxesContainer">
                <div className="UpperBoxes">
                    <div className="UpperLeftBox">
                        <p className="UpperBoxTitle">AI-Generering</p>
                        <GPTAnswer answer='50-70 % av frågorna i många upphandlingar har redan besvarats i tidigare anbud. Med Hedda kan du luta dig tillbaka och låta vår AI ta hand om dem. Vår avancerade AI skriver skräddarsydda svar baserat på kravställningar och frågor, samtidigt som den integrerar innehåll från era egna dokument. Hedda är utvecklad specifikt för bidteam och hjälper er att producera anbud på noll tid.' />

                    </div>
                    <div className="UpperRightBox">
                        <p className="UpperBoxTitle">Källhänvisning</p>
                        <p className="UpperBoxText">Allt material som produceras av vår AI är källhänvisat. Du ser vilka dokument och stycken som ligger till grund för svaren, på så sätt kan du känna dig trygg.</p>
                        <img className="GifImage" src={`${process.env.PUBLIC_URL}/scource.gif`} alt="Källhänvisning GIF" />
                    </div>
                </div>
                <div className="LowerBoxes">
                    <div className="LowerLeftBox">
                        <div>
                            <p className="LowerBoxTitle">AI-redigera</p>
                            <p className="LowerBoxText">Redigera skriven text effektivt, med smidiga och andvändbara verktyg.</p>
                        </div>
                        <img className="BottomImg" src={`${process.env.PUBLIC_URL}/edit.svg`} alt="Chatta med upphandlingsdokumenten GIF" />
                    </div>
                    <div className="LowerMiddleBox">
                        <div>
                            <p className="LowerBoxTitle">Spara tid</p>
                            <p className="LowerBoxText">Få feedback på hur era svar kan förbättras och undvik vanliga slarvfel.</p>
                        </div>
                        <img className="BottomImg" src={`${process.env.PUBLIC_URL}/comment.svg`} alt="Spara tid GIF" />
                    </div>
                    <div className="LowerRightBox">
                        <div>
                            <p className="LowerBoxTitle">Stressfri administration</p>
                            <p className="LowerBoxText">Tilldela frågor, ange granskare och se status för anbudet. Vi gör administrationen av anbudet busenkelt.</p>
                        </div>
                        <img className="BottomImg" src={`${process.env.PUBLIC_URL}/admin.svg`} alt="Spara tid GIF" />

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Features;