import './Header.css';

function Header(props) {
    return (
        <div className='HeaderContainer'>
            <div className='HeaderLogo'>
                <img className='HeaderLogoImage' src="HeddaLogoNormalBlack.svg" alt="Logo"/>
                </div>
            <div className='NavBar'>
                <a href='#getstarted'>Kom igång</a>
                <a href='#features'>Vår Lösning</a>
                <a href='#safety'>Säkerhet</a>
            </div>
        </div>
    )
}

export default Header;