import './Safety.css';
import FeatureShowcase from '../../components/FeatureShowcase/FeatureShowcase';
import { IconShieldLockFilled, IconUsers, IconUserCheck, IconHandOff, IconLockFilled } from '@tabler/icons-react';


function Safety() {
  return (
    <div className='SafetySegmentContainer' id="safety">
      <p className='SafetyHeaderText'>Vi tar <span style={{ color: '#0075FF' }}>säkerheten</span> på allvar</p>
      <p className='SafetyIntroductionText'>Ett anbudsverktyg som ni kan våra trygga med</p>
      <div className='SafetyContainer'>
        <FeatureShowcase
          icon={<IconShieldLockFilled size={36} color='black' />}
          title="Krypterat"
          text="AES-256-kryptering används för data i vila och TLS 1.2 används för data under överföring."
        />

        <FeatureShowcase
          icon={<IconUsers size={36} color='black' />}
          title="I enlighet med GDPR"
          text="Hedda följer GDPR samt arbetar enligt CIS ramverket. Ingen data lämnar EU."
        />

        <FeatureShowcase
          icon={<IconUserCheck size={36} color='black' />}
          title="Ingen träning på data"
          text="Ingen av er data används från att träna våra modeller. Precis som det låter är er data just det, er."
        />

        <FeatureShowcase
          icon={<IconHandOff size={36} color='black' />}
          title="Driftsäkerhet"
          text="Skyddar mot skadlig kod av högsta standard, samt sekretessavtal med personal, kunder och leverantörer."
        />

      </div>
    </div>
  )

}

export default Safety;