import './Startpage.css';
import Header from '../../segments/Header/Header';
import HeroSection from '../../segments/HeroSection/HeroSection';
import BidPlatform from '../../segments/BidPlatform/BidPlatform';
import Safety from '../../segments/Safety/Safety';
import Chat from '../../segments/Chat/Chat';
import AnswerGeneration from '../../segments/AnswerGeneration/AnswerGeneration';
import SearchDemo from '../../segments/SearchDemo/SearchDemo';
import Questions from '../../segments/Questions/Questions';
import Footer from '../../segments/Footer/Footer';
import Features from '../../segments/Features/Features';
function Startpage() {
    return (
        <div className="StartpageBody">
            {/* 
            
            
            <AnswerGeneration />
            <SearchDemo />
            <Questions />
             */}
            <Header/>
            <HeroSection />
            <BidPlatform />
            <Features />
            <Chat/>
            <Safety/>
            <Footer />

        </div>
    );
}

export default Startpage;