import './GPTAnswer.css';
import React from 'react';
import { useRef, useEffect } from 'react';

function GPTAnswer({ question, answer }) {

    const generatedAnswerRef = useRef(null);
    const timeoutRef = useRef(null);
    const answerList = answer.split(" ");
    const parts = answer.split('<br>');

    useEffect(() => {
        let i = 0;

        const addWord = () => {
            if (i < answerList.length) {
                generatedAnswerRef.current.innerHTML += " " + answerList[i];
                i++;

                if (i < answerList.length) {
                    var wordLength = answerList[i].length;
                    var multiplier = wordLength / 5;
                    const randomDelay = Math.floor(Math.random() * 50 * multiplier) + 70;
                    timeoutRef.current = setTimeout(addWord, randomDelay);
                }
            }
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    observer.disconnect();

                    if (answerList.length > 0) {
                        generatedAnswerRef.current.innerHTML = answerList[0]; // Add the first word immediately
                        i = 1; // Start from the second word
                        const randomDelay = Math.floor(Math.random() * 50) + 70;
                        timeoutRef.current = setTimeout(addWord, randomDelay);
                    }
                }
            });
        });

        if (generatedAnswerRef.current) {
            observer.observe(generatedAnswerRef.current);
        }

        return () => {
            clearTimeout(timeoutRef.current); // Clean up timeout on component unmount
            if (observer && generatedAnswerRef.current) {
                observer.unobserve(generatedAnswerRef.current);
            }
        };
    }, [answerList]);


    return (
        <div className="GPTAnswerContainer">
            {question != "" &&
                <div className="GPTAnswerQuestionContainer">
                    <h2 className="GPTAnswerQuestion">{question}</h2>
                </div>}
            <div className="GPTAnswerAnswerContainer">
                <p className="GPTAnswerAnswerFiller">
                    {parts.map((part, index) => (
                        <React.Fragment key={index}>
                            {part}
                            {index < parts.length - 1 && <br />}
                        </React.Fragment>
                    ))}
                </p>
                <p className="GPTAnswerAnswer" ref={generatedAnswerRef} ></p>
            </div>
        </div>
    );
}

export default GPTAnswer;