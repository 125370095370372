import './BidPlatform.css';

function BidPlatform() {
    return (
        <div className='BidPlatformContainer '>
            <div className='BidPlatformSubContainerLeft'>
                <div className='BidPlatformTextContainer'>
                    <p className='BidPlatformIntroductionText'>Vår plattform</p>
                    <p className='BidPlatformHeaderText'>Er <span style={{color: '#0075FF'}}>nya</span> anbudsyta.</p>
                    <p className='BidPlatformDescriptionText'>Anbudsprocessen är ofta tidskrävande och komplex. Vi underlättar den genom att ta fram utkast, identifiera relevant information och granska era svar. Dessutom erbjuder vi projektledningsverktyg som samarbetet mellan er. Med Hedda förenklar ni ert anbudsarbete och kan leverera högkvalitativa anbud snabbare och effektivare. </p>
                </div>
            </div>
            <div className='BidPlatformSubContainerRight'>
                <iframe
                    
                    src="https://www.youtube.com/embed/zJhFBSMOtUU?rel=0&modestbranding=1&showinfo=0&autohide=1&controls=1"
                   
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className='BidPlatformVideo'
                ></iframe>
            </div>

        </div>
    )
}


export default BidPlatform;