import './HeroSection.css';
import ShortcutButton from '../../components/ShortcutButton/ShortcutButton';
import ModalComponent from '../../components/ModalComponent/ModalComponent';
import { useDisclosure } from '@mantine/hooks';

function HeroSection() {
    const [opened, { open, close }] = useDisclosure(false);

    return (
        <div className="HeroSectionContainer" id="getstarted">
            <ModalComponent opened={opened} close={close} />
            <div className="HeroSectionContent">
                <div className="HeroSectionTextButtonContainer">
                    
                        <p className="HeroSectionTextTitle"><span style={{ color: '#0075FF' }}>Effektivisera</span> hur ni skriver anbud</p>
                        <p className="HeroSectionText">Kom igång kostnadsfritt idag</p>
                    
                    <div className="HeroSectionButtonContainer">
                        <ShortcutButton text="Boka en demo" onClick={open} />
                    </div>
                </div>

            </div>

        </div>
    );
}

export default HeroSection;